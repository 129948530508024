import { Flex } from "@chakra-ui/react";
import { Image } from "@fluentui/react-components";
// S T Y L E S
import Styles from "../styles/useStyles";
// I C O N S
import Traslado from "../assets/icons/1_traslado1.svg";
import Ceremonia from "../assets/icons/2_ceremonia.svg";
import Traslado2 from "../assets/icons/3_traslado2.svg";
import Coctel from "../assets/icons/4_coctel.svg";
import Recepcion from "../assets/icons/5_recepcion.svg";
// I M A G E S
import Jacona from "../assets/imgs/4_HaciendaJacona.png";
import Church from "../assets/imgs/3_TemploLosAngeles.png";
import CardTemplate from "../widgets/cardTemplate";
import LineDivider from "../assets/imgs/16_itinerario linea.png";

function ItineraryComponent(props) {
  const styles = Styles();
  return (
    <div className={styles.ItineraryContainer}>
      <h2>Itinerario</h2>
      <div className={styles.ScheduleContainer}>
        <Flex
          direction={"column"}
          alignItems={"end"}
          gap={"30px"}
          style={{
            width: props.isMobile ? "40%" : "28%",
            textAlign: "right",
          }}
        >
          <div>
            <h5>15:30 hrs</h5>
            <h4>Traslado</h4>
            <p>Desde hoteles sede al Templo Nuestra Señora de Los Ángeles.</p>
          </div>
          <Image src={Ceremonia} width={50} />
          <div>
            <h5>17:00 hrs</h5>
            <h4>Traslado</h4>
            <p>
              Del Templo Nuestra Señora de Los Ángeles a Hacienda la Jacona.
            </p>
          </div>
          <Image src={Coctel} width={40} />
          <div>
            <h5>19:00 hrs</h5>
            <h4>Recepción</h4>
            <p>Hacienda la Jacona</p>
          </div>
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            width: "15%",
          }}
        >
          {props.isMobile ? (
            <div>.</div>
          ) : (
            <Image src={LineDivider} width={"9%"} />
          )}
        </Flex>
        <Flex
          direction={"column"}
          alignItems={"start"}
          gap={"40px"}
          style={{
            width: props.isMobile ? "40%" : "35%",
          }}
        >
          <Image src={Traslado} width={50} />

          <div>
            <h5>16:00 hrs</h5>
            <h4 style={props.isMobile ? { lineHeight: 1 } : {}}>
              Ceremonia religiosa
            </h4>
            <p>
              Templo de Nuestra <br /> Señora de Los Ángeles
            </p>
          </div>

          <Image src={Traslado2} width={50} />

          <div>
            <h5>17:30 hrs</h5>
            <h4 style={props.isMobile ? { lineHeight: 1 } : {}}>
              Cóctel de bienvenida
            </h4>
            <p>Hacienda la Jacona</p>
          </div>
          <Image src={Recepcion} width={50} />
        </Flex>
      </div>
      {/* C A R D S */}
      <Flex
        direction={props.isMobile ? "column" : "row"}
        gap={"20px"}
        marginTop={"15%"}
      >
        <CardTemplate
          isMobile={props.isMobile}
          mapLink="https://maps.app.goo.gl/DA2n6oYDtJUretvZ9"
          imagePreview={Church}
          header="ceremonia"
          title="Templo Nuestra Señora de Los Ángeles"
          address=" C. Aquiles Serdan 1380, Los Ángeles, 34076 Durango, Dgo."
        />
        <CardTemplate
          isMobile={props.isMobile}
          mapLink="https://maps.app.goo.gl/AT1x77EZ6xiAGm4K9"
          imagePreview={Jacona}
          header="recepción"
          title="Hacienda Jacona"
          address="Domicilio Conocido, Dolores Hidalgo, Dgo, Mexico, 34307"
        />
      </Flex>
    </div>
  );
}

export default ItineraryComponent;
