import { Flex } from "@chakra-ui/react";

// S T Y L E S
import Styles from "../styles/useStyles";

// W I D G E T S
import TimeValue from "../widgets/timeValue";
import { useCallback, useEffect, useRef, useState } from "react";

function SaveTheDateComponent(props) {
  const styles = Styles();

  const isItToday = () => {
    const now = new Date();
    return now.getMonth() === 10 && now.getDate() === 2;
  };

  const padNumber = (number) => (number < 10 ? `0${number}` : `${number}`);

  const intervalRef = useRef(null);
  const [esHoy, setEsHoy] = useState(false);
  const weddingDay = "02 nov 2024 16:00:00";
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const updateTimeLeft = useCallback(() => {
    const now = new Date();
    const expireTime = new Date(weddingDay).getTime();
    const timeDiffInMs = expireTime - now.getTime();
    if (timeDiffInMs <= 0) {
      window.clearInterval(intervalRef.current);
      setEsHoy(true);
    }
    const sec = 1000;
    const min = sec * 60;
    const hrs = min * 60;
    const day = hrs * 24;

    setTimeLeft({
      days: Math.floor(timeDiffInMs / day),
      hours: Math.floor((timeDiffInMs % day) / hrs),
      minutes: Math.floor((timeDiffInMs % hrs) / min),
      seconds: Math.floor((timeDiffInMs % min) / sec),
    });
  }, [weddingDay]);

  useEffect(() => {
    if (isItToday()) {
      setEsHoy(true);
      return;
    }
    updateTimeLeft();
    intervalRef.current = window.setInterval(updateTimeLeft, 1000);

    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, [updateTimeLeft]);

 

  return (
    <div className={styles.FlowerBackgroundImage}>
      <Flex>
        <h1
          className={styles.SaveDateText}
          style={props.isMobile ? { fontSize: "28px" } : {}}
        >
          save
        </h1>
        <p className={styles.The}
         style={props.isMobile ? { fontSize: "18px" } : {}}
        >the</p>
        <h1
          className={styles.SaveDateText}
          style={props.isMobile ? { fontSize: "28px" } : {}}
        >
          date
        </h1>
      </Flex>

      {esHoy ? (
        <p className={styles.The}>¡Es hoy, es hoy!</p>
      ) : (
        <Flex>
          <TimeValue
            isMobile={props.isMobile}
            time={padNumber(timeLeft.days)}
            feature="días"
          />
          <TimeValue
            isMobile={props.isMobile}
            time={padNumber(timeLeft.hours)}
            feature="horas"
          />
          <TimeValue
            isMobile={props.isMobile}
            time={padNumber(timeLeft.minutes)}
            feature="mins"
          />
          <TimeValue
            isMobile={props.isMobile}
            time={padNumber(timeLeft.seconds)}
            feature="segs"
          />
        </Flex>
      )}
    </div>
  );
}

export default SaveTheDateComponent;
