import Styles from "../styles/useStyles";

function IntroComponent(props) {
  const styles = Styles();
  return (
    <div className={styles.IntroMessage}>
      <p className={styles.IntroText} style={props.isMobile ? {fontSize:'12px'} : {}}>
        Con la bendición de Dios y de nuestros padres Roberto Serrato Salazar,
        Laura Sara Lara Subia, Juan Manuel Ramírez Gutiérrez y Verónica González
        Sustaita, tenemos el honor de invitarlos a nuestra ceremonia religiosa,
        Sábado 2 de Noviembre a las 16:00 en el templo Nuestra Señora de Los
        Ángeles. Posteriormente agradecemos su presencia en la Hacienda La
        Jacona. <br />
        <br /> Durango, Durango, 2024
      </p>
    </div>
  );
}

export default IntroComponent;
