import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
// import { Accordion, AccordionPanel, AccordionItem, AccordionHeader, Divider } from "@fluentui/react-components";
import Styles from "../styles/useStyles";
import { ChevronDown } from "lucide-react";
import { Divider } from "@fluentui/react-components";

function CustomAccordionItem(props) {
  return (
    <>
      <AccordionItem>
        <AccordionButton
          style={{
            border: "none",
            backgroundColor: "transparent",
          }}
        >
          <Box as="span" flex="1" textAlign="left">
            <p style={{ color: "black" }}>{props.question}</p>
          </Box>
          <AccordionIcon color="#9C3E24" fontSize={"25px"} />
          {/* <ChevronDown color="#9C3E24" /> */}
        </AccordionButton>
        <AccordionPanel pb={4}>
          <p style={{ color: "#525252", paddingLeft: "15px" }}>
            {props.answer}
          </p>
          <p style={{ color: "#525252", paddingLeft: "15px" }}>
            {props.anwser2}
          </p>
          <p style={{ color: "#525252", paddingLeft: "15px" }}>
            {props.answer3}
          </p>
        </AccordionPanel>
      </AccordionItem>
      <Divider />
    </>
  );
}

function FaqComponent(props) {
  const styles = Styles();

  return (
    <div className={styles.FaqContainer}>
      <h2
        style={props.isMobile ? { textAlign: "center", lineHeight: 1.5 } : {}}
      >
        Preguntas frecuentes
      </h2>
      <div style={{ width: "90%", marginTop: "5%" }}>
        <Accordion defaultIndex={[0]} allowMultiple>
          <CustomAccordionItem
            question="1. ¿Qué restaurantes recomiendan?"
            answer=" Para desayunos: Wallander, Mamboreta, Hotel Gobernador, La Magdalena. Comidas
                y cenas: Luganos, Fonda de la Tía Chona, Mamboreta."
          />
          <CustomAccordionItem
            question=" 2. ¿Puedo llegar por mi propia cuenta a la recepción en la
                Hacienda Jacona?"
            answer="Para tu comodidad, nosotros proporcionaremos transporte de los
                hoteles sede a la ceremonia y de la ceremonia a la recepción,
                revisa la sección de “horarios” para mayor información. Sin
                embargo, sí es posible llegar en Uber y con auto propio tanto al
                templo de Los Ángeles como a Hacienda La Jacona. Toma en
                consideración que la Hacienda La Jacona está fuera del área
                metropolitana de Durango, por lo que es importante estar al
                pendiente del transporte una vez que finalice la boda. "
          />
          <CustomAccordionItem
            question="3. ¿Hay un código de descuento en los hoteles sede?"
            answer=" Sí, para que les tomen en cuenta el descuento, es importante
                hacer la reservación vía telefónica. A través de plataformas
                como Booking.com; hotels.com; etc. no es posible introducir el
                código de descuento."
          />
          <CustomAccordionItem
            question="4. ¿A qué hora es el check-in y check-out en los hoteles sede?"
            answer="El check-in es a las 15:00, sin embargo, pudiera hacerse antes
                dependiendo de la disponibilidad del cuarto. El check-out es a
                las 12:00."
          />
          <CustomAccordionItem
            question="5. ¿Cuál es la manera más fácil de llegar a Durango?"
            answer="El transporte nocturno en autobús es más cómodo de lo que creen.
                El viaje en autobús que recomendamos es en el Omnibus de Mexico
                Plus de dos pisos que sale de la central de autobuses de
                Monterrey a las 23:00 y llega a Durango a las 6:00."
            anwser2="
                 El roadtrip también es cómodo a través de la carretera de cuota
                pasando por Saltillo y Torreón. Se hacen aproximadamente 6 horas
                y media de camino. El costo total de casetas es de
                aproximadamente $1,500 pesos. "
            answer3="
                También hay vuelos entre Monterrey y Durango los viernes y los
                domingos con escala en la CDMX a través de Aeroméxico. Revisa la
                sección de “Traslado a Durango” de este sitio para mayor
                información."
          />
          <CustomAccordionItem
            question="6. ¿Cómo me regreso de la recepción al hotel sede?"
            answer=" Proporcionaremos vans que saldrán de la Hacienda La Jacona hacia
                los hoteles sede a cada hora a partir de la finalización del
                vals."
          />
          <CustomAccordionItem
            question="7. ¿Qué tan probable es que encuentres un alacrán?"
            answer="¿Uno vivo? Muy poco probable."
          />
          <CustomAccordionItem
            question="8. ¿Cómo va a estar el clima en esas fechas?"
            answer=" El clima en noviembre es generalmente fresco y seco. Las
                temperaturas promedio varían entre 10°C por la noche y 23°C
                durante el día. Es recomendable llevar ropa abrigadora para la
                noche."
          />
        </Accordion>
      </div>
    </div>
  );
}

export default FaqComponent;
