import { Button, Image } from "@fluentui/react-components";
import { Flex } from "@chakra-ui/react";

//S T Y L E S
import Styles from "../styles/useStyles";

// W I D G E T S
import NavButton from "../widgets/navButton";

// A S S E T S
import LogoSJ from "../assets/icons/0_monograma.svg";
import { useEffect, useState } from "react";

function NavbarComponent(props) {
  const styles = Styles();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const widthWindow = window.innerWidth;
    console.log(widthWindow);

    widthWindow < 840 ? setIsMobile(true) : setIsMobile(false);
  }, [isMobile]);

  return (
    <div className={styles.NavBarContainer}>
      <div style={{ margin: "15px 30px 0" }}>
        <Image src={LogoSJ} width={30} />
      </div>
      {isMobile ? (
        <></>
      ) : (
        <div style={{ margin: "0 30px 0" }}>
          <Flex>
            <NavButton id={props.idIntro} btnText={"nos casamos"} />
            <NavButton id={props.idItinerary} btnText={"itinerario"} />
            <NavButton id={props.idGifts} btnText={"mesa de regalos"} />
            <NavButton id={props.idLogistic} btnText={"logística"} />
            <NavButton id={props.idFaqs} btnText={"FAQs"} />
            <button
              onClick={() => window.location.replace(props.idRsvp)}
              className={styles.RSPVBtn}
            >
              RSVP
            </button>
          </Flex>
        </div>
      )}
    </div>
  );
}

export default NavbarComponent;
