import { Flex } from "@chakra-ui/react";
import Styles from "../styles/useStyles";
import {
  Button,
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { useState } from "react";
import { Check, Send, X } from "lucide-react";

function ConfirmComponent(props) {
  const styles = Styles();

  const [confirmation, setConfirmation] = useState("");
  const [validatedData, setValidatedData] = useState(false);

  function sendResponse() {
    const formEle = document.querySelector("form");
    const formData = new FormData(formEle);

    formData.append("Confirm", confirmation);

    if (
      formData.has("Name") &&
      formData.has("Phone") &&
      formData.has("Extra") &&
      formData.has("Confirm")
    ) {
      fetch(
        "https://script.google.com/macros/s/AKfycbxtMEuJIW8lfupPiG4ExfkMmHdzE9pDEr4tnn73OEuuk4Bp9X0rNkRE3UbauyNNAVezkQ/exec",
        { method: "POST", body: formData }
      ).then((res) => {
        if (res.status === 200) {
          notify();
          setConfirmation("");
          formEle.reset();
        }
      });
    } else {
      console.log("empty form");
      noData();
    }
  }

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = () =>
    dispatchToast(
      <Toast>
        <ToastTitle>¡Muchas gracias!</ToastTitle>
        <ToastBody>Tu confirmación ha sido envida</ToastBody>
      </Toast>,
      { intent: "success" }
    );

  const noData = () =>
    dispatchToast(
      <Toast>
        <ToastTitle>¡Ups! No se envió tu confirmación</ToastTitle>
        <ToastBody>Por favor asegurate de llenar todos los campos</ToastBody>
      </Toast>,
      { intent: "error" }
    );
  return (
    <div className={styles.ConfirmContainer}>
      <Toaster toasterId={toasterId} />

      <Flex direction={"column"} alignItems={"center"}>
        <h2
          style={
            props.isMobile
              ? {
                  lineHeight: 1.5,
                  color: "white",
                  textAlign: "center",
                }
              : { color: "white" }
          }
        >
          Confirma tu asistencia{" "}
        </h2>

        <form className={styles.FormContainer}>
          <h5 className={styles.LabelForm}>Nombre(s) completo(s).</h5>

          <input
            name="Name"
            id="name"
            type="text"
            required
            className={styles.InputForm}
          />

          <h5 className={styles.LabelForm}>Teléfono</h5>

          <input
            name="Phone"
            id="phone"
            type="tel"
            className={styles.InputForm}
          />

          <h5 className={styles.LabelForm}>¿Te acompañará alguien?</h5>

          <Flex gap={"20px"}>
            <div>
              <input
                name="Extra"
                id="yes"
                type="radio"
                value="Si"
                className={styles.RadioBtn}
              />
              <label for="yes" className={styles.LabelForm}>
                Si
              </label>
            </div>

            <div>
              <input
                name="Extra"
                id="no"
                type="radio"
                value="No"
                className={styles.RadioBtn}
              />
              <label for="no" className={styles.LabelForm}>
                No
              </label>
            </div>
          </Flex>

          <Flex width={"100%"} gap={"20px"}>
            <Button
              onClick={() => {
                setConfirmation("Si asistiré");
              }}
              className={styles.BtnForm}
              appearance="transparent"
              icon={<Check />}
            >
              Si asistiré
            </Button>
            <Button
              onClick={() => {
                setConfirmation("No asistiré");
              }}
              className={styles.BtnForm}
              appearance="transparent"
              icon={<X />}
            >
              No asistiré
            </Button>
          </Flex>
          <Button
            onClick={() => sendResponse()}
            className={styles.SubmitBtn}
            icon={<Send />}
          >
            Enviar
          </Button>
        </form>
      </Flex>
    </div>
  );
}

export default ConfirmComponent;
