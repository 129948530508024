import Styles from "../styles/useStyles";

function TimeValue(props) {
  const styles = Styles();
  return (
    <div
      className={styles.CountdownDiv}
      style={
        props.isMobile ? { padding: "30px 15px 0" } : { padding: "10% 30px 0" }
      }
    >
      <div
        className={styles.CountdownNumbers}
        style={
          props.isMobile
            ? {
                fontSize: "30px",
              }
            : {}
        }
      >
        {props.time}
      </div>
      <p
        className={styles.CountdownText}
        style={
          props.isMobile
            ? {
                fontSize: "16px",
              }
            : {}
        }
      >
        {" "}
        {props.feature}{" "}
      </p>
    </div>
  );
}

export default TimeValue;
