import {
  Body1,
  Button,
  Card,
  CardHeader,
  CardPreview,
  Image,
} from "@fluentui/react-components";

import Styles from "../styles/useStyles";

import Map from "../assets/icons/14_maps.png";
import { ChevronRight, CircleDollarSign, MapPin, Phone } from "lucide-react";
import { Flex } from "@chakra-ui/react";

function CardTemplate(props) {
  const styles = Styles();
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 0,
      }}
    >
      <div>
        <CardPreview
          logo={
            <Button
              className={styles.CardBtn}
              onClick={() => window.open(props.mapLink, "_blank")}
            >
              <Image src={Map} width={"13px"} style={{ paddingRight: "5px" }} />
              <p className={styles.BtnMapText}>Ver mapa</p>
              <ChevronRight color="#6C2F22" width={"15px"} />
            </Button>
          }
        >
          <Image src={props.imagePreview} />
        </CardPreview>
        <div
          style={
            props.isMobile
              ? { padding: "16px" }
              : { padding: "16px", minHeight: "104px" }
          }
        >
          <CardHeader
            header={<Body1 className={styles.Body1}>{props.header}</Body1>}
          />
          <h4 style={{ fontSize: "17px" }}>{props.title}</h4>
          {props.address ? (
            <Flex>
              <MapPin color="#6C2F22" width={14} />
              <p style={{ fontSize: "12px", margin: "0 5px 0" }}>
                {props.address}
              </p>
            </Flex>
          ) : (
            <></>
          )}
          {props.phone ? (
            <Flex alignItems={"center"}>
              <Phone color="#6C2F22" width={13} />
              <p style={{ fontSize: "12px", margin: "0 5px 0", lineHeight: 0 }}>
                {props.phone}
              </p>
            </Flex>
          ) : (
            <></>
          )}
          {props.discount ? (
            <Flex >
              <CircleDollarSign color="#6C2F22" width={13} />
              <p
                style={{
                  fontSize: "12px",
                  margin: "0 5px 0",
                
                }}
              >
                {props.discount}
              </p>
            </Flex>
          ) : (
            <></>
          )}
          {props.notes ? (
            <p style={{ margin: 0, color: "#656565", fontSize: "12px" }}>
              {props.notes}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {props.isSalon ? (
        <div
          style={{
            backgroundColor: `${props.color}`,
            width: "10px",
          }}
        ></div>
      ) : (
        <></>
      )}
    </Card>
  );
}

export default CardTemplate;
