import { Card, CardPreview, Image } from "@fluentui/react-components";
import { Flex } from "@chakra-ui/react";
import Styles from "../styles/useStyles";

import Dress from "../assets/imgs/6_Dresscode_Mujeres.png";
import Suit from "../assets/imgs/7_Dresscode_Hombres.png";

function DresscodeComponent(props) {
  const styles = Styles();
  return (
    <div>
      <div
        className={styles.DressCodeContainer}
        style={props.isMobile ? { paddingBottom: "10%" } : {}}
      >
        <h2 style={{ color: "white" }}>Dress code</h2>
        <h4 style={{ color: "white" }}>Formal</h4>
        {props.isMobile ? (
          <Flex 
          gap={'10px'}
          className={styles.CardContainerMobile}>
            <div>
              <Image src={Dress} width={110} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4>Mujeres</h4>
                <p style={{ marginBottom: 0 }}>Vestido largo</p>
                <p style={{ margin: 0 }}>No blanco</p>
              </div>
            </div>
            <div>
              <Image src={Suit} width={100.5} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4>Hombres</h4>
                <p>Traje</p>
                <p></p>
              </div>
            </div>
          </Flex>
        ) : (
          <div
            className={styles.CardsContainer}
            style={{ marginTop: "10%", marginBottom: "50%" }}
          >
            <Card
              style={{
                padding: "3% 5% 2%",
                margin: "0 3% 0",
                marginLeft: "30%",
              }}
            >
              <Image src={Dress} width={110} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4>Mujeres</h4>
                <p style={{ marginBottom: 0 }}>Vestido largo</p>
                <p style={{ margin: 0 }}>No blanco</p>
              </div>
            </Card>
            <Card style={{ padding: "3% 5% 0", margin: "0 3% 0" }}>
              <Image src={Suit} width={100} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4>Hombres</h4>
                <p>Traje</p>
                <p></p>
              </div>
            </Card>
          </div>
        )}
      </div>
      {props.isMobile ? (
        <></>
      ):(
        <div
        style={{
          height: "50vh",
          backgroundColor: "#fcf6f0",
        }}
      ></div>
      )}
    </div>
  );
}

export default DresscodeComponent;
