import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import WeddinInvitation from "./screens/weddingInvitation";

// F O N T S //
import "./fonts/TAN-MONCHERI.woff";
import "./fonts/Montserrat-ExtraBold.ttf";
import "./fonts/Montserrat-Black.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-Thin.ttf";
import './fonts/Amalfi-Coast/Amalfi-Coast.ttf';
import './fonts/NT-Fabulous/NT-Fabulous.otf';
import './fonts/Baskervville/Baskervville-Regular.ttf';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FluentProvider theme={webLightTheme}>
      <WeddinInvitation></WeddinInvitation>
    </FluentProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
