import { Image } from "@fluentui/react-components";
import { Flex } from "@chakra-ui/react";

import Styles from "../styles/useStyles";

import Map from "../assets/imgs/5_MapaUbicaciones.png";
import Arrow from "../assets/icons/6_arrow-right.svg";

function TransportComponent(props) {
  const styles = Styles();
  return (
    <div className={styles.TransportContainer}>
      <h2>Transporte</h2>
      <p style={{ textAlign: "center" }}>
        Para tu comodidad, ofreceremos servicio de transporte que estará
        saliendo de los dos hoteles sede. Las rutas serán las siguientes: 
      </p>
      <Image fit="contain" src={Map} />
      {props.isMobile ? (
        <>
          <Flex>
            <p style={{ color: "#863726", paddingRight: "10px" }}>1</p>
            <p style={{ textAlign: "center" }}>
              Única salida en van desde los hoteles sede al Templo Nuestra
              Señora de los Ángeles
            </p>
          </Flex>
          <Flex>
            <p style={{ color: "#863726" }}>2</p>
            <p style={{ textAlign: "center" }}>
              Única salida en van desde el Templo Nuestra Señora de los Ángeles
              a Hacienda La Jacona
            </p>
          </Flex>
          <Flex>
            <p style={{ color: "#863726" }}>3</p>
            <p style={{ textAlign: "center" }}>
              Hacienda La Jacona a Hoteles sede (salida cada hora a partir de la
              finalización del vals)
            </p>
          </Flex>
        </>
      ) : (
        <>
          <Flex>
            <p style={{ color: "#863726" }}>1</p>
            <p> Única salida en van desde los hoteles sede</p>
            <Image src={Arrow} />
            <p>Templo Nuestra Señora de los Ángeles</p>
          </Flex>
          <Flex>
            <p style={{ color: "#863726" }}>2</p>
            <p>
              Única salida en van desde el Templo Nuestra Señora de los Ángeles
            </p>
            <Image src={Arrow} />
            <p>Hacienda La Jacona</p>
          </Flex>
          <Flex>
            <p style={{ color: "#863726" }}>3</p>
            <p>Hacienda La Jacona</p>
            <Image src={Arrow} />
            <p>
              Hoteles sede (salida cada hora a partir de la finalización del
              vals)
            </p>
          </Flex>
        </>
      )}
    </div>
  );
}

export default TransportComponent;
