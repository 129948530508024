import { makeStyles } from "@fluentui/react-components";

// I M A G E S
import SaveTheDateBg from "../assets/imgs/bgs/1_SaveTheDate.png";
import ItineraryBg from "../assets/imgs/bgs/2_Itinerario.png";
import DresscodeBg from "../assets/imgs/bgs/3_DressCode.png";
import GiftTableBg from "../assets/imgs/bgs/4_MesaDeRegalos.png";
import ConfirmBg from "../assets/imgs/12_RSPV.png";
import FooterBg from "../assets/imgs/bgs/5_Footer.png";
import SaraJuanBg from "../assets/imgs/1_Hero.png";
import IntroBg from "../assets/imgs/2_Intro.png";

const Styles = makeStyles({
  // MAIN SECTION
  MainImagebackground: {
    backgroundImage: `url(${SaraJuanBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "60%",
    width: "100%",
  },
  NavBarContainer: {
    backgroundImage: `linear-gradient(180deg, rgba(68,71,48,1) 6%, rgba(255,255,255,0) 100%);`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 3%",
    position: "fixed",
    width: "100%",
    zIndex: 1,
  },
  RSPVBtn: {
    fontFamily: "NT-Fab",
    color: "white",
    border: "none",
    borderRadius: "4px",
    textTransform: "capitalize",
    fontWeight: 300,
    fontSize: "18px",
    padding: "0 20px 0",
    backgroundColor: `rgb(255, 255, 255, .14)`,
    "&:active": { border: "1px solid white" },
    "&:focus": { border: "1px solid white" },
  },

  SaraYJuanText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "3% 0 29%",
  },
  H1: {
    fontFamily: "Amalfi",
    color: "white",
    fontSize: "40px",
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "center",
    marginBottom: 0,
  },
  H2: {
    fontFamily: "NT-Fab",
    textTransform: "uppercase",
    color: "white",
    fontWeight: 300,
    lineHeight: 0,
    fontSize: "18px",
  },
  // SAVE THE DATE SECTION
  FlowerBackgroundImage: {
    backgroundImage: ` url(${SaveTheDateBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "7% 0 7%",
    width: "100%",
  },
  SaveDateText: {
    fontFamily: "Amalfi",
    fontSize: "38px",
    color: "#9C3E24",
    fontWeight: 300,
    textTransform: "capitalize",
  },
  The: {
    color: "#9C3E24",
    fontFamily: "NT-Fab",
    fontSize: "28px",
    fontWeight: 400,
    padding: "0 15px 0",
  },
  CountdownDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  CountdownNumbers: {
    color: "#9C3E24",
    fontFamily: "NT-Fab",
    fontSize: "42px",
    fontWeight: 300,
    lineHeight: 0,
  },
  CountdownText: {
    color: "#AC7C6F",
    textTransform: "uppercase",
    fontFamily: "Basker",
    fontWeight: 300,
    fontSize: "22px",
  },
  // INTRO SECTION
  IntroMessage: {
    backgroundImage: `url(${IntroBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "7%",
    paddingLeft: "40%",
    backgroundColor: `rgb(0,0,0,0.4)`,
    backgroundBlendMode: "darken",
  },
  IntroText: {
    textAlign: "right",
    color: "white",
    fontSize: "18px",
    fontFamily: "Basker",
    lineHeight: 1.2,
  },
  // ITINERARY SECTION
  ItineraryContainer: {
    backgroundImage: `url(${ItineraryBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#fcf6f0",
    backgroundPosition: "0 100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 20% 15%",
  },
  ScheduleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  CardBtn: {
    border: "1px solid white",
    borderRadius: "32px",
    opacity: 0.6,
    padding: "0 0 0",
    "&:hover": { opacity: 1 },
  },
  BtnMapText: {
    lineHeight: 0,
    margin: 0,
    color: "#6C2F22",
    fontWeight: 300,
    fontSize: "10px",
  },
  Body1: {
    fontFamily: "NT-Fab",
    fontSize: "13px",
    textTransform: "uppercase",
    color: "#9C3E24",
    letterSpacing: 1,
  },
  // TRANSPORT SECTION
  TransportContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fcf6f0",
    padding: "5% 10% 5%",
  },
  // DRESS CODE SECTION
  DressCodeContainer: {
    backgroundImage: `url(${DresscodeBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 10% 10%",
  },
  CardsContainer: {
    display: "flex",
    position: "absolute",
    width: "100%",
  },
  CardContainerMobile:{
    padding:'5% 5% 0',
    margin:'5% 0 0',
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
  
  },
  // GFT TABLE SECTION
  GiftTableContainer: {
    backgroundImage: `url(${GiftTableBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#fcf6f0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 10% 5%",
  },
  GiftTableCard: {
    backgroundColor: "#6C2F22",
    width: "22vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "2%",
    borderRadius: "5px",
    cursor:'pointer',
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
    "&:hover": { scale: 1.02 },
  },
  GiftTableCardMobile: {
    backgroundColor: "#6C2F22",
    minWidth:'80%',
    maxHeight:'15vh',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15%",
    borderRadius: "5px",
    cursor:'pointer',
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);",
    "&:hover": { scale: 1.02 },
  },
  // LOGISTICS SECTION
  LogisticsContainer: {
    backgroundColor: "#fcf6f0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 10% 5%"
  },
  LogisticBtn: {
    border: "1px solid #9C3E24",
    backgroundColor: "transparent",
    color: "#9C3E24",
    borderRadius: "40px",
    fontFamily: "Basker",
    fontWeight: 300,
    "&:hover": {
      backgroundColor: `rgb(156, 62, 36, .2)`,
      color: "#9C3E24",
      border: "1px solid #9C3E24",
    },
    "&:active": {
      backgroundColor: `rgb(156, 62, 36, .2)`,
      color: "#9C3E24",
      border: "1px solid #9C3E24",
    },
    "&:focus": { backgroundColor: "#9C3E24", color: "white" },
  },
  // CONFIRM YOUR ATTENDANCE SECTION
  ConfirmContainer: {
    backgroundImage: `url(${ConfirmBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: `rgb(0,0,0,0.4)`,
    backgroundBlendMode: "darken",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    padding: "5% 10% 5%",
  },
  FormContainer: {
    width: "100%",
    padding: "5% 10% 5% ",
    borderRadius: "8px",
    border: "1px solid #525252",
    backgroundColor: `rgb(68, 71, 49, 0.4)`,
  },
  LabelForm: {
    color: "white",
    fontSize: "17px",
    fontFamily: "NT-Fab",
  },
  InputForm: {
    width: "100%",
    border: "none",
    border: "1px solid #F9EADB",
    borderRadius: "4px",
    backgroundColor: "transparent",
    color: "#F9EADB",
    padding: "5px",
    outline: "none",
  },
  RadioBtn: {
    appearance: "none",
    width: "15px",
    height: "15px",
    border: "1px solid #F9EADB",
    borderRadius: "50%",
    marginRight: "10px",
    "&:checked": { backgroundColor: "#F9EADB" },
  },
  BtnForm: {
    width: "100%",
    margin: "8% 0 5%",
    border: "1px solid #F9EADB",
    borderRadius: "4px",
    color: "#F9EADB",
    fontFamily: "NT-Fab",
    fontWeight: 300,
    fontSize: "15px",
    "&:hover": { backgroundColor: "#F9EADB", color: "#6C2F22" },
    "&:focus": { backgroundColor: "#F9EADB", color: "#6C2F22" },
  },
  SubmitBtn: {
    width: "100%",
    opacity: "30%",
    fontFamily: "NT-Fab",
    fontSize: "15px",
    backgroundColor: "#F9EADB",
    color: "#6C2F22",
    "&:hover": { opacity: 1, color: "#6C2F22" },
  },
  // FAQ SECTION
  FaqContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 10% 5%",
    backgroundColor: "#fcf6f0",
  },
  // FOOTER SECTION
  FooterContainer: {
    backgroundImage: `url(${FooterBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "5% 3% 5%",
  },
  FlowerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fcf6f0",
    width: "65px",
    height: "65px",
    position: "absolute",
    margin: "-8% 0 0",
    borderRadius: "50px",
  },
});

export default Styles;
