import Styles from "../styles/useStyles";
import { Image } from "@fluentui/react-components";
import { Flex } from "@chakra-ui/react";
//
import LogoSJ from "../assets/icons/0_monograma.svg";
import Cempa from "../assets/icons/21_cempasuchil.svg";

function FooterComponent(props) {
  const styles = Styles();
  return (
    <div style={{ width: "100%" }}>
      <Flex direction={"column"} className={styles.FooterContainer}>
        <h4
          style={{
            color: "white",
            textTransform: "uppercase",
            letterSpacing: "3px",
          }}
        >
          Sara y Juan Manuel
        </h4>
        <p
          style={{
            color: "white",
            fontSize: "18px",
            textTransform: "uppercase",
            marginBottom: "3%",
          }}
        >
          2 noviembre 2024
        </p>
        <Image
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          src={LogoSJ}
          width={25}
        />
        {props.isMobile ? (
          <></>
        ) : (
          <div className={styles.FlowerContainer}>
            <Image src={Cempa} />
          </div>
        )}
      </Flex>
    </div>
  );
}

export default FooterComponent;
