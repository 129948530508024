import { Box, Flex } from "@chakra-ui/react";
import Styles from "../styles/useStyles";
import { Card, CardPreview, Image } from "@fluentui/react-components";

// A S S E T S
import AmazonIcon from "../assets/icons/7_amazon.svg";
import LiverpoolIcon from "../assets/icons/8_liverpool.svg";
import BBVAIcon from "../assets/icons/9_bbva.svg";

function GiftTableComponent(props) {
  const styles = Styles();
  return (
    <div className={styles.GiftTableContainer}>
      <h2
      style={props.isMobile ? { textAlign: "center", lineHeight: 1.5 } : {}}
      >Mesa de regalos</h2>
      <p style={{ textAlign: "center" }}>
        Estamos muy felices de compartir este día tan especial con ustedes. Para
        quienes deseen hacernos un obsequio, hemos habilitado mesas de regalos y
        también una cuenta bancaria:
      </p>
      <Flex
        direction={props.isMobile ? "column" : "row"}
        alignItems={props.isMobile ? "center" : ""}
        gap={"20px"}
        style={{ width: "100%", marginTop: "5%" }}
        justifyContent={"space-between"}
      >
        <Box
          onClick={() =>
            window.open(
              "https://www.amazon.com.mx/wedding/share/sarayjuanmanuel",
              "_blank"
            )
          }
          className={props.isMobile ? styles.GiftTableCardMobile : styles.GiftTableCard}
                 >
          <Image src={AmazonIcon} />
        </Box>
        <Box
          className={props.isMobile ? styles.GiftTableCardMobile : styles.GiftTableCard}
          style={props.isMobile ? { width: "60%" } : {}}
          onClick={() =>
            window.open(
              "https://mesaderegalos.liverpool.com.mx/milistaderegalos/51444483",
              "_blank"
            )
          }
        >
          <Image src={LiverpoolIcon} />
          <p style={{ color: "white", textAlign: "center" }}>
            Número de evento: 51444483
          </p>
        </Box>

        <div
          className={props.isMobile ? styles.GiftTableCardMobile : styles.GiftTableCard}
          style={props.isMobile ? { width: "60%" } : {}}
        >
          <Image src={BBVAIcon} width={100} />
          <p style={{ color: "white", fontSize: "12px", textAlign: "center" }}>
            Número de cuenta: 047 057 9106 <br />
            Cuenta CLABE: 0125 8000 4705 791063 <br />
            Nombre de titular: Sara Serrato Lara <br />
            (Favor de referenciar con su nombre completo)
          </p>
        </div>
      </Flex>
    </div>
  );
}

export default GiftTableComponent;
