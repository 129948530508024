import { background, Flex } from "@chakra-ui/react";
import Styles from "../styles/useStyles";
import {
  Button,
  Divider,
  Image,
  Overflow,
  OverflowItem,
} from "@fluentui/react-components";
import { useState } from "react";
import CardTemplate from "../widgets/cardTemplate";

import HotelGobernador from "../assets/imgs/8_Gobernador.png";
import HotelHampton from "../assets/imgs/9_HamptonInn.png";
import PosadaMaria from "../assets/imgs/10_PosadaDeMaria.png";
import FiestaInn from "../assets/imgs/11_FiestaInn.png";

import Carretera from "../assets/icons/18_carretera.svg";
import Autobus from "../assets/icons/19_autobús.svg";
import Avion from "../assets/icons/20_avión.svg";

import Vuelo1 from "../assets/imgs/vueloIda.png";
import Vuelo2 from "../assets/imgs/vueloVuelta1.png";
import Vuelo3 from "../assets/imgs/vueloVuelta2.png";
import { Bed, Hotel, PlaneTakeoff, Sparkles } from "lucide-react";

function TrasladoInfo(props) {
  return (
    <Flex gap={"20px"}>
      <Flex
        direction={"column"}
        minWidth={"16%"}
        alignItems={"center"}
        gap={"15px"}
      >
        <h4>{props.title}</h4>
        <Image src={props.icon} width={"100px"} />
      </Flex>
      <Flex direction={"column"} justifyContent={"start"}>
        {props.nota ? (
          <div
            style={{
              fontFamily: "NT-Fab",
              color: "#6c2f22",
              fontSize: "24px",
              fontWeight: 300,
              marginBottom: "10px",
            }}
          >
            Omnibus de México Plus
          </div>
        ) : (
          <></>
        )}
        <div
          style={{ color: "#464646", fontFamily: "Basker", fontSize: "16px" }}
        >
          {props.info}
        </div>
        <li
          onClick={props.openLink}
          style={{
            color: "#464646",
            fontFamily: "Basker",
            fontSize: "14px",
            marginLeft: "10px",
            cursor: "pointer",
            "&:hover": { backgroundColor: "pink" },
          }}
        >
          {props.link}
        </li>
        <p style={{ fontSize: "12px" }}>{props.nota}</p>
      </Flex>
    </Flex>
  );
}

function TrasladoInfoMobile(props) {
  return (
    <Flex gap={"20px"} direction={"column"}>
      <Flex gap={"20px"}>
        <Image src={props.icon} width={"40px"} />
        <h4 style={{ fontSize: "19px" }}>{props.title}</h4>
      </Flex>
      {props.nota ? (
        <div
          style={{
            fontFamily: "NT-Fab",
            color: "#6c2f22",
            fontSize: "22px",
            fontWeight: 300,
            marginBottom: "10px",
          }}
        >
          Omnibus de México Plus
        </div>
      ) : (
        <></>
      )}
      <div style={{ color: "#464646", fontFamily: "Basker", fontSize: "16px" }}>
        {props.info}
        <span
          onClick={() => window.open(props.link, "_blank")}
          style={{
            borderBottom: "1px solid #863726",
            color: "#863726",
            cursor: "pointer",
          }}
        >
          {props.link}
        </span>
      </div>
      <p style={{ color: "#656565" }}>{props.nota}</p>
    </Flex>
  );
}

function Traslado(props) {
  return (
    <Flex direction={"column"} gap={"30px"}>
      {props.isMobile ? (
        <>
          <TrasladoInfoMobile
            title="Por carretera"
            icon={Carretera}
            info="Puedes irte en carro tomando la autopista a Saltillo, después pasar
            por Torreón y llegar a Durango. El costo total de casetas es de
            aproximadamente $1,500 pesos: "
            link=" https://maps.app.goo.gl/qCk5SwegL4AfdM7m8"
          />
          <TrasladoInfoMobile
            title="Por autobus"
            icon={Autobus}
            info=" El viaje en autobús que recomendamos es en el Omnibus de Mexico Plus
            de dos pisos que sale de la central de autobuses de Monterrey a las
            23:00 y llega a Durango a las 6:00. "
            link="https://odm.com.mx/"
            nota="NOTA: Hay muchas otras opciones más de horarios de salida, salen los
            autobuses de Monterrey a Durango cada 2 horas. Asegúrate de salir
            desde la central de autobuses del centro de Monterrey. "
          />
          <Flex gap={"20px"} direction={"column"}>
            <Flex gap={"20px"}>
              <Image src={Avion} width={"22px"} />
              <h4 style={{ fontSize: "19px" }}>Por avión</h4>
            </Flex>
            <div
              style={{
                fontFamily: "NT-Fab",
                color: "#6c2f22",
                fontSize: "22px",
                fontWeight: 300,
                marginBottom: "10px",
              }}
            >
              Vuelo de ida
            </div>
            <Image src={Vuelo1} />
            <div
              style={{
                fontFamily: "NT-Fab",
                color: "#6c2f22",
                fontSize: "22px",
                fontWeight: 300,
                marginBottom: "10px",
              }}
            >
              Vuelo de vuelta
            </div>
            <Image src={Vuelo2} />
            <Image src={Vuelo3} />
          </Flex>
        </>
      ) : (
        <>
          <TrasladoInfo
            title="Por carretera"
            icon={Carretera}
            info="Puedes irte en carro tomando la autopista a Saltillo, después pasar
            por Torreón y llegar a Durango. El costo total de casetas es de
            aproximadamente $1,500 pesos."
            link=" https://maps.app.goo.gl/qCk5SwegL4AfdM7m8"
            openLink={() =>
              window.open("https://maps.app.goo.gl/qCk5SwegL4AfdM7m8", "_blank")
            }
          />
          <TrasladoInfo
            title="Por autobus"
            icon={Autobus}
            info=" El viaje en autobús que recomendamos es en el Omnibus de Mexico Plus
            de dos pisos que sale de la central de autobuses de Monterrey a las
            23:00 y llega a Durango a las 6:00."
            link="https://odm.com.mx/"
            nota="NOTA: Hay muchas otras opciones más de horarios de salida, salen los
            autobuses de Monterrey a Durango cada 2 horas. Asegúrate de salir
            desde la central de autobuses del centro de Monterrey. "
          />
          <Flex gap={"20px"}>
            <Flex
              direction={"column"}
              minWidth={"16%"}
              alignItems={"center"}
              gap={"10px"}
            >
              <h4>Por avión</h4>
              <Image src={Avion} width={"80px"} />
            </Flex>
            <Flex gap={"30px"}>
              <Flex direction="column" gap={"20px"}>
                <h4 style={{ fontSize: "24px" }}>Vuelo de ida</h4>
                <Image src={Vuelo1} width={"100%"} />
              </Flex>
              <Divider vertical />
              <Flex direction="column" gap={"20px"}>
                <h4 style={{ fontSize: "24px" }}>Vuelo de ida</h4>
                <Flex gap={"20px"}>
                  <Image src={Vuelo2} width={"50%"} />
                  <Image src={Vuelo3} width={"50%"} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}

function HotelesSede(props) {
  return (
    <Flex direction={props.isMobile ? "column" : "row"} gap={"30px"}>
      <CardTemplate
        isMobile={props.isMobile}
        mapLink="https://maps.app.goo.gl/UWfabQsWJ7ZXJQZs8"
        imagePreview={HotelGobernador}
        title="Hotel Gobernador"
        phone="Tel: 618 827 2500"
        discount="Código de descuento: 1568706/Boda Sara y Juan Manuel"
        address="Av. 20 de noviembre 257, Zona Centro, 34000 Durango, Dgo."
        notes="NOTA: es importante que te contactes vía telefónica con el área de “eventos” para que identifiquen el código de descuento."
      />
      <CardTemplate
        isMobile={props.isMobile}
        mapLink="https://maps.app.goo.gl/Pmw2PykwhkrLwdcX8"
        imagePreview={HotelHampton}
        title="Hotel Hampton Inn"
        phone="Tel: 618 150 2000"
        discount="Código de descuento: Boda Sara y Juan Manuel"
        address="Av. Heróico Colegio Militar 101, Nueva Vizcaya, 34080 Durango, Dgo."
        notes="NOTA: es importante que te contactes por teléfono para que te puedan hacer válido el código de descuento. Este hotel incluye desayuno buffet."
      />
    </Flex>
  );
}

function HotelesAdicionales(props) {
  return (
    <Flex direction={props.isMobile ? "column" : "row"} gap={"30px"}>
      <CardTemplate
        isMobile={props.isMobile}
        mapLink="https://maps.app.goo.gl/omQWDxKRtdvzR7Hx5"
        imagePreview={PosadaMaria}
        title="Hotel Boutique Posada de María"
        phone="Tel: 618 425 6830"
        address="Calle 5 de Febrero 912, Zona Centro, 34000 Durango, Dgo."
      />
      <CardTemplate
        isMobile={props.isMobile}
        mapLink="https://maps.app.goo.gl/dYtxKZSrLv2ntt3S7"
        imagePreview={FiestaInn}
        title="Fiesta Inn (Paseo Durango)"
        address="Avenida, Blvd. Felipe Pescador 1401, La Esperanza, 34080 Durango, Dgo."
        phone="Tel: 618 150 0900"
      />
    </Flex>
  );
}

function Salones(props) {
  return (
    <Flex direction={props.isMobile ? "column" : "row"} gap={"30px"}>
      <Flex
        direction={"column"}
        gap={"30px"}
        style={
          props.isMobile
            ? {}
            : {
                maxWidth: "32vw",
              }
        }
      >
        <CardTemplate
          isMobile={props.isMobile}
          isSalon={true}
          color="#9C3E24"
          title="Azeneth Gómez Salón"
          phone="Tel: 618 813 0015"
          address="Calle Miguel de Cervantes Saavedra 213 B, Zona Centro, 34000 Durango, Dgo."
        />
        <CardTemplate
          isMobile={props.isMobile}
          isSalon={true}
          color="#444731"
          title="Total Hair Salon"
          phone="Tel: 618 155 5531"
          address="Av. Heróico Colegio Militar 103, Nueva Vizcaya, 34080 Durango, Dgo."
        />
      </Flex>
      <Flex
        direction={"column"}
        gap={"30px"}
        style={
          props.isMobile
            ? {}
            : {
                maxWidth: "32vw",
              }
        }
      >
        <CardTemplate
          isMobile={props.isMobile}
          isSalon={true}
          color="#AF7D59"
          title="María de México (Sucursal Plaza Las Torres)"
          phone="Tel: 618 829 3666"
          address="Ana Leyva 204, Nueva Vizcaya, 34080 Durango, Dgo."
        />{" "}
        <CardTemplate
          isMobile={props.isMobile}
          isSalon={true}
          color="#D55B55"
          title="María de México (Sucursal Lomas)"
          phone="Tel: 618 827 9285"
          address="C. P.º de la Joya 118, Lomas del Parque, 34100 Durango, Dgo."
        />
      </Flex>
    </Flex>
  );
}

function LogisticsComponent(props) {
  const styles = Styles();
  const [traslado, setTraslado] = useState(false);
  const [hotelesSede, setHotelesSede] = useState(true);
  const [hotelesAdicionales, setHotelesAdicionales] = useState(false);
  const [salones, setSalones] = useState(false);
  return (
    <div
      className={styles.LogisticsContainer}
      style={props.isMobile ? {} : { minHeight: "100%" }}
    >
      <h2>Logística</h2>
      <p style={{ textAlign: "center" }}>
        Para tu comodidad, hemos preparado toda la información necesaria sobre
        traslados, hoteles y servicios adicionales que podrás encontrar en
        Durango.
      </p>
      {/* BUTTON MENÚ */}

      <Flex
        width={"100%"}
        gap={props.isMobile ? "20px" : ""}
        justifyContent={"space-evenly"}
        margin={"1% 0 6%"}
        overflowX={props.isMobile ? "scroll" : "hidden"}
      >
        
        <Button
          onClick={() => {
            setTraslado(true);
            setHotelesSede(false);
            setHotelesAdicionales(false);
            setSalones(false);
          }}
          className={styles.LogisticBtn}
          style={
            props.isMobile
              ? {
                  minWidth: "180px",
                }
              : {}
          }
          icon={<PlaneTakeoff size={15} />}
        >
          Traslado a Durango
        </Button>
        <Button
          onClick={() => {
            setTraslado(false);
            setHotelesSede(true);
            setHotelesAdicionales(false);
            setSalones(false);
          }}
          className={styles.LogisticBtn}
          style={
            props.isMobile
              ? {
                  minWidth: "180px",
                }
              : {}
          }
          icon={<Hotel size={15} />}
        >
          Hoteles sede
        </Button>
        <Button
          onClick={() => {
            setTraslado(false);
            setHotelesSede(false);
            setHotelesAdicionales(true);
            setSalones(false);
          }}
          className={styles.LogisticBtn}
          style={
            props.isMobile
              ? {
                  minWidth: "180px",
                }
              : {}
          }
          icon={<Bed size={15} />}
        >
          Hoteles adicionales
        </Button>
        <Button
          onClick={() => {
            setTraslado(false);
            setHotelesSede(false);
            setHotelesAdicionales(false);
            setSalones(true);
          }}
          className={styles.LogisticBtn}
          style={
            props.isMobile
              ? {
                  minWidth: "180px",
                }
              : {}
          }
          icon={<Sparkles size={15} />}
        >
          Salones de belleza
        </Button>
      </Flex>
      {traslado ? <Traslado isMobile={props.isMobile} /> : <></>}
      {hotelesSede ? <HotelesSede isMobile={props.isMobile} /> : <></>}
      {hotelesAdicionales ? (
        <HotelesAdicionales isMobile={props.isMobile} />
      ) : (
        <></>
      )}
      {salones ? <Salones isMobile={props.isMobile} /> : <></>}
    </div>
  );
}

export default LogisticsComponent;
