// S T Y L E S
import Styles from "../styles/useStyles";

// C O M P O N E N T S
import NavbarComponent from "../components/NavbarComponent";
import SaveTheDateComponent from "../components/SaveDateComponent";
import ItineraryComponent from "../components/IntineraryComponent";
import TransportComponent from "../components/transportComponent";
import DresscodeComponent from "../components/dressCodeComponent";
import GiftTableComponent from "../components/GiftTableComponent";
import LogisticsComponent from "../components/LogisticsComponent";
import ConfirmComponent from "../components/ConfirmComponent";
import FaqComponent from "../components/FaqComponent";
import FooterComponent from "../components/FooterComponent";
import IntroComponent from "../components/IntroComponent";
import { useEffect, useState } from "react";

function WeddinInvitation() {
  const styles = Styles();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const widthWindow = window.innerWidth;
    widthWindow < 1024 ? setIsMobile(true) : setIsMobile(false);
  }, [isMobile]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.MainImagebackground}>
        <NavbarComponent
          idIntro="/#Intro"
          idItinerary="/#Itinerary"
          idGifts="/#GiftTable"
          idLogistic="/#Logistic"
          idFaqs="/#Faqs"
          idRsvp="/#Rsvp"
        />

        <div className={styles.SaraYJuanText}>
          <h1
            className={styles.H1}
            style={isMobile ? { fontSize: "30px", marginTop: "25%" } : {}}
          >
            Sara y <br /> Juan Manuel
          </h1>
          <h2 className={styles.H2}>2 noviembre 2024</h2>
          {isMobile ? (
            <button
              onClick={() => window.location.replace("/#Rsvp")}
              className={styles.RSPVBtn}
              style={
                isMobile
                  ? {
                      marginTop: "5%",
                      backgroundColor: `rgb(255, 255, 255, .3)`,
                    }
                  : {}
              }
            >
              RSVP
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <SaveTheDateComponent isMobile={isMobile} />
      <div id="Intro">
        <IntroComponent isMobile={isMobile} />
      </div>
      <div id="Itinerary">
        <ItineraryComponent isMobile={isMobile} />
      </div>
      <TransportComponent isMobile={isMobile} />
      <DresscodeComponent isMobile={isMobile} />
      <div id="GiftTable">
        <GiftTableComponent isMobile={isMobile} />
      </div>
      <div id="Logistic">
        <LogisticsComponent isMobile={isMobile} />
      </div>
      <div id="Rsvp">
        <ConfirmComponent isMobile={isMobile} />
      </div>
      <div id="Faqs">
        <FaqComponent isMobile={isMobile} />
      </div>
      <FooterComponent isMobile={isMobile} />
    </div>
  );
}

export default WeddinInvitation;
