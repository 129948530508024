import { background } from "@chakra-ui/react";
import { Button, makeStyles } from "@fluentui/react-components";

const Styles = makeStyles({
  btn: {
    fontFamily: "NT-Fab",
    color: "white",
    textTransform: "capitalize",
    fontWeight: 300,
    fontSize: "18px",
    "&:hover": { color: "#444731" },
    "&:focus": { color: "#444731" },
    "&:active": { color: "white" },
  },
});

const btnStyle = {
  rootHovered: {
    backgroundColor: "red",
  },
};

function NavButton(props) {
  const styles = Styles();
  return (
    <Button
      onClick={() => window.location.replace(props.id)}
      className={styles.btn}
      style={{ "&:active": { color: "white" } }}
      appearance="transparent"
    >
      {props.btnText}
    </Button>
  );
}

export default NavButton;
